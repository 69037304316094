import { Account, Transaction, User } from "financify-models/types";
import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";

export const parseFirebaseUser = (user: firebase.User): User => {
  return {
    uid: user.uid,
    displayName: user.displayName || "undefined",
    email: user.email || "undefined",
    photoURL: user.photoURL,
  };
};

export const generateNewAccount = (): Account => ({
  id: uuidv4(),
  title: "",
  isShared: false,
  description: null,
  createdAt: new Date().toISOString(),
});

export const generateNewTransaction = (): Transaction => ({
  id: uuidv4(),
  title: "",
  cost: 0,
  period: 1,
  description: null,
  account: "",
  dayOfPayment: null,
  firstPayment: null,
  lastPayment: null,
  createdAt: new Date().toISOString(),
});

export const calculateTotal = (transactions: Transaction[]) => {
  return transactions.reduce(function (prev, cur) {
    return prev + cur.cost;
  }, 0);
};
