import { Account, Transaction, User } from "financify-models/types";
import { derived } from "overmind";

export type NotificationType = "success" | "error" | "warning" | "info";

export type StateApp = {
  isInitialized: boolean;
  isLoading: boolean;
  notification: {
    show: boolean;
    message: string;
    type: NotificationType;
  };
};

export type StateUser = {
  isAuthenticated: boolean;
  data: User | null;
};

export type State = {
  app: StateApp;
  user: StateUser;
  accounts: {
    isLoading: boolean;
    data: Account[];
  };
  transactions: {
    isLoading: boolean;
    data: Transaction[];
  };
};

export const state: State = {
  app: {
    isInitialized: false,
    isLoading: false,
    notification: {
      show: false,
      message: "",
      type: "info",
    },
  },
  user: {
    isAuthenticated: derived((user: StateUser) => user.data !== null),
    data: null,
  },
  accounts: {
    isLoading: true,
    data: [],
  },
  transactions: {
    isLoading: true,
    data: [],
  },
};
