import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { useStore } from "../../store";

const AUTO_HIDE = 6000;

const useStyles = makeStyles((theme) => ({
  snackbar: {
    bottom: `${theme.spacing(8)}px !important`,
    [theme.breakpoints.down("sm")]: {
      bottom: `${theme.spacing(2)}px !important`,
    },
  },
  alert: {
    width: "100%",
  },
}));

export const AppSnackbar = () => {
  const { state, actions } = useStore();
  const classes = useStyles();

  const onClose = () => actions.app.notification.close();

  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={state.app.notification.show}
      onClose={() => onClose()}
      autoHideDuration={AUTO_HIDE}
      disableWindowBlurListener
    >
      <Alert
        onClose={() => onClose()}
        severity={state.app.notification.type}
        className={classes.alert}
      >
        {state.app.notification.message}
      </Alert>
    </Snackbar>
  );
};
