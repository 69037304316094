import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";
import { useHistory } from "react-router-dom";
import { APP_NAME } from "../../config";
import { useStore } from "../../store";

const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: 0.5,
    fontWeight: 900,
  },
  menu: {
    marginLeft: "auto",
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export const AppHeader = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useStore();

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Button onClick={() => history.push("/")}>
            <Typography className={classes.title}>{APP_NAME}</Typography>
          </Button>
          {state.app.isInitialized && state.user.data && (
            <div className={classes.menu}>
              <Button onClick={() => history.push("/transactions")}>
                transactions
              </Button>
              <Button onClick={() => history.push("/accounts")}>
                accounts
              </Button>
              <IconButton
                color="inherit"
                onClick={() => history.push("/profile")}
              >
                {state.user.data.photoURL ? (
                  <Avatar
                    alt={state.user.data.displayName}
                    src={state.user.data.photoURL}
                    className={classes.avatar}
                  />
                ) : (
                  <Avatar className={classes.avatar}>
                    <PersonIcon fontSize="small" />
                  </Avatar>
                )}
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
