import { Account, Transaction } from "financify-models/types";
import { createErr, createOk, Result } from "option-t/cjs/PlainResult";
import { Action, AsyncAction } from "overmind";
import { parseFirebaseUser } from "../utils";
import { NotificationType } from "./state";

// @ts-ignore
let transactionsUnsubscribe = null;
// @ts-ignore
let accountsUnsubscribe = null;

export const user: {
  doSignIn: AsyncAction;
  doSignOut: AsyncAction;
} = {
  doSignIn: async ({ state, actions, effects }) => {
    state.app.isLoading = true;

    const result = await effects.user.signIn();

    state.app.isLoading = false;

    if (result.ok) {
      const user = parseFirebaseUser(result.val);
      state.user.data = user;
      actions.data.subscribe();

      actions.app.notification.show({
        message: "Successfully signed in",
        type: "success",
      });
    } else {
      actions.app.notification.show({
        message: "Failed to sign in",
        type: "error",
      });
    }
  },
  doSignOut: async ({ state, actions, effects }) => {
    state.app.isLoading = true;

    // @ts-ignore
    if (transactionsUnsubscribe) transactionsUnsubscribe();
    // @ts-ignore
    if (accountsUnsubscribe) accountsUnsubscribe();

    await effects.user.signOut();

    state.user.data = null;
    state.app.isLoading = false;

    actions.app.notification.show({
      message: "Successfully signed out",
      type: "success",
    });
  },
};

export const app: {
  notification: {
    show: Action<{ message: string; type: NotificationType }, void>;
    close: Action;
  };
} = {
  notification: {
    show: ({ state }, payload) => {
      state.app.notification.show = true;
      state.app.notification.message = payload.message;
      state.app.notification.type = payload.type;
    },
    close: ({ state }) => {
      state.app.notification.show = false;
    },
  },
};

export const data: {
  subscribe: AsyncAction;
  accounts: {
    add: AsyncAction<{ account: Account }, Result<null, null>>;
    update: AsyncAction<{ account: Account }, Result<null, null>>;
    delete: AsyncAction<{ account: Account }, Result<null, null>>;
  };
  transactions: {
    add: AsyncAction<{ transaction: Transaction }, Result<null, null>>;
    update: AsyncAction<{ transaction: Transaction }, Result<null, null>>;
    delete: AsyncAction<{ transaction: Transaction }, Result<null, null>>;
  };
} = {
  subscribe: async ({ state, effects }) => {
    const user = state.user.data;
    if (!user) return;

    state.accounts.data = await effects.data.accounts.getAll(user.uid);
    state.accounts.isLoading = false;

    accountsUnsubscribe = effects.data.accounts.subscribe(
      user.uid,
      (accounts) => {
        state.accounts.data = accounts;
        if (state.accounts.isLoading) state.accounts.isLoading = false;
      }
    );
    transactionsUnsubscribe = effects.data.transactions.subscribe(
      user.uid,
      (entries) => {
        state.transactions.data = entries;
        if (state.transactions.isLoading) state.transactions.isLoading = false;
      }
    );
  },
  accounts: {
    add: async ({ state, actions, effects }, payload) => {
      // TODO
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.accounts.add(
        state.user.data.uid,
        payload.account
      );

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully created",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to create",
          type: "error",
        });
        return createErr(null);
      }
    },
    update: async ({ state, actions, effects }, payload) => {
      // TODO
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.accounts.update(payload.account);

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully updated",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to update",
          type: "error",
        });
        return createErr(null);
      }
    },
    delete: async ({ state, actions, effects }, payload) => {
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.accounts.remove(payload.account);

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully deleted",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to delete",
          type: "error",
        });
        return createErr(null);
      }
    },
  },
  transactions: {
    add: async ({ state, actions, effects }, payload) => {
      // TODO
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.transactions.add(
        state.user.data.uid,
        payload.transaction
      );

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully created",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to create",
          type: "error",
        });
        return createErr(null);
      }
    },
    update: async ({ state, actions, effects }, payload) => {
      // TODO
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.transactions.update(payload.transaction);

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully updated",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to update",
          type: "error",
        });
        return createErr(null);
      }
    },
    delete: async ({ state, actions, effects }, payload) => {
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.transactions.remove(payload.transaction);

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully deleted",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to delete",
          type: "error",
        });
        return createErr(null);
      }
    },
  },
};
