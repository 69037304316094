import { Account, Transaction, User } from "financify-models/types";
import { createNone, createSome, Option } from "option-t/cjs/PlainOption";
import { createErr, createOk } from "option-t/cjs/PlainResult";
import * as firebaseAPI from "../services/Firebase";
import { parseFirebaseUser } from "../utils";

export const user = {
  get: async (): Promise<Option<User>> =>
    new Promise((resolve) => {
      try {
        const unsubscribe = firebaseAPI.auth.onAuthStateChanged((resp) => {
          unsubscribe();
          if (!resp) return resolve(createNone());
          const user = parseFirebaseUser(resp);
          return resolve(createSome(user));
        });
      } catch (error) {
        console.log(error);
        debugger;
        return resolve(createNone());
      }
    }),
  signIn: async () => firebaseAPI.signIn(),
  signOut: async () => firebaseAPI.signOut(),
};

export const data = {
  accounts: {
    subscribe: (uid: string, callback: (accounts: Account[]) => void) => {
      const unsubscribe = firebaseAPI.db
        .collection("accounts")
        .where("uid", "==", uid)
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          const items: Account[] = [];
          snapshot.forEach((doc) => {
            const { uid, ...rest } = doc.data();
            // @ts-ignore
            items.push({
              ...rest,
              id: doc.id,
            });
          });
          callback(items);
        });
      return unsubscribe;
    },
    getAll: async (uid: string) => {
      const snapshot = await firebaseAPI.db
        .collection("accounts")
        .where("uid", "==", uid)
        .orderBy("createdAt", "desc")
        .get({ source: "cache" });

      const items: Account[] = [];
      snapshot.forEach((doc) => {
        const { uid, ...rest } = doc.data();
        // @ts-ignore
        items.push({
          ...rest,
          id: doc.id,
        });
      });
      return items;
    },
    add: async (uid: string, account: Account) => {
      try {
        const { id, ...rest } = account;
        const response = await firebaseAPI.db.collection("accounts").add({
          ...rest,
          uid,
        });
        return createOk(response);
      } catch (error) {
        return createErr(error);
      }
    },
    update: async (account: Account) => {
      try {
        const { id, ...rest } = account;
        await firebaseAPI.db
          .collection("accounts")
          .doc(account.id)
          .update({
            ...rest,
          });
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
    remove: async (account: Account) => {
      try {
        await firebaseAPI.db.collection("accounts").doc(account.id).delete();
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
  },
  transactions: {
    subscribe: (
      uid: string,
      callback: (transaction: Transaction[]) => void
    ) => {
      const unsubscribe = firebaseAPI.db
        .collection("transactions")
        .where("uid", "==", uid)
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          const items: Transaction[] = [];
          snapshot.forEach((doc) => {
            const { uid, ...rest } = doc.data();
            // @ts-ignore
            items.push({
              ...rest,
              id: doc.id,
            });
          });
          callback(items);
        });
      return unsubscribe;
    },
    add: async (uid: string, transaction: Transaction) => {
      try {
        const { id, ...rest } = transaction;
        const response = await firebaseAPI.db.collection("transactions").add({
          ...rest,
          uid,
        });
        return createOk(response);
      } catch (error) {
        return createErr(error);
      }
    },
    update: async (transactions: Transaction) => {
      try {
        const { id, ...rest } = transactions;
        await firebaseAPI.db
          .collection("transactions")
          .doc(transactions.id)
          .update({
            ...rest,
          });
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
    remove: async (transactions: Transaction) => {
      try {
        await firebaseAPI.db
          .collection("transactions")
          .doc(transactions.id)
          .delete();
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
  },
};
