import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AppFooter } from "./components/AppFooter";
import { AppHeader } from "./components/AppHeader";
import { AppSnackbar } from "./components/AppSnackbar";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { FullPageLoader } from "./components/FullPageLoader";
import { FullPageLoadingIndicator } from "./components/FullPageLoadingIndicator";
import { UnauthenticatedRoute } from "./components/UnauthenticatedRoute";
import { useStore } from "./store";
import { theme, useStyles } from "./theme";

const Home = lazy(() => import("./pages/Home"));
const Profile = lazy(() => import("./pages/Profile"));
const Login = lazy(() => import("./pages/Login"));
const Logout = lazy(() => import("./pages/Logout"));
const Accounts = lazy(() => import("./pages/Accounts"));
const AccountNew = lazy(() => import("./pages/AccountNew"));
const AccountEdit = lazy(() => import("./pages/AccountEdit"));
const Transactions = lazy(() => import("./pages/Transactions"));
const TransactionNew = lazy(() => import("./pages/TransactionNew"));
const TransactionEdit = lazy(() => import("./pages/TransactionEdit"));

export const App = () => {
  const classes = useStyles();
  const { state } = useStore();

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {state.app.isInitialized !== true ? (
          <main className={classes.content}>
            <FullPageLoader />
          </main>
        ) : (
          <>
            <Router>
              <AppHeader />
              <main className={classes.content}>
                <Suspense fallback={<FullPageLoader />}>
                  <Switch>
                    <AuthenticatedRoute path="/" exact>
                      <Home />
                    </AuthenticatedRoute>
                    <UnauthenticatedRoute path="/login" exact>
                      <Login />
                    </UnauthenticatedRoute>
                    <AuthenticatedRoute path="/logout" exact>
                      <Logout />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/profile" exact>
                      <Profile />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/accounts" exact>
                      <Accounts />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/accounts/new" exact>
                      <AccountNew />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/accounts/:id" exact>
                      <AccountEdit />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/transactions" exact>
                      <Transactions />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/transactions/new" exact>
                      <TransactionNew />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/transactions/:id" exact>
                      <TransactionEdit />
                    </AuthenticatedRoute>
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                </Suspense>
              </main>
              <footer>
                <AppFooter />
              </footer>
            </Router>
          </>
        )}
        <AppSnackbar />
        <FullPageLoadingIndicator />
      </ThemeProvider>
    </>
  );
};
